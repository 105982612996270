import React from "react";
import GoToTop from "../../GoToTop/GoToTop";
import AboutSection from "../../Home/AboutSection/AboutSection";
import "./aboutpage.css"

const missionList = [
  {
    id: 1,
    icon: "bi bi-yelp",
    title: "Reduce Carbon Footprints",
    description:
      "Through innovative carbon reduction strategies, we help businesses and institutions minimize their environmental impact and achieve carbon neutrality.",
  },
  {
    id: 2,
    icon: "bi bi-yelp",
    title: "Enhance Climate Resilience",
    description:
      "We support the development of climate-resilient infrastructure and practices that withstand the impacts of a changing climate, safeguarding communities and businesses.",
  },
  {
    id: 3,
    icon: "bi bi-yelp",
    title: "Drive Sustainable Innovation",
    description:
      "We empower our clients to adopt green technologies, optimize resources, and integrate sustainability into their core business models.",
  },
  {
    id: 4,
    icon: "bi bi-yelp",
    title: "Promote Climate Leadership",
    description:
      "We inspire and enable our clients to become leaders in sustainability, demonstrating a commitment to environmental responsibility that resonates with stakeholders and customers alike.",
  },
];

const AboutPage = () => {
  return (
    <>
      <div className="about-page">
        <AboutSection />

        <div className="container who-we-are mt-5 mb-5">
          <h1 className="fw-bold">Who We Are?</h1>
          <div className="mt-5 text-1 d-flex justify-content-between flex-wrap text-start gap-5">
            <p className="col-md-6">
              We are a team of dedicated climate experts, sustainability
              consultants, and industry leaders with extensive experience in
              climate science, carbon management, green business practices,
              finance, business and management. Our diverse backgrounds and
              unique global perspectives enable us to offer cutting-edge
              solutions that combine technical expertise with practical
              implementation.
            </p>
            <p className="col-md-5">
              From carbon project development and renewable energy transition to
              climate-resilient investments and corporate sustainability
              strategies, we work across a range of sectors to deliver
              high-impact climate and sustainable solutions. Our holistic
              approach ensures that our clients not only meet regulatory
              requirements but also seize the opportunities for innovation,
              growth, and leadership in sustainability.
            </p>
          </div>
        </div>
        {/* our vision */}
        <div className="container vision mt-5 mb-5 align-content-center">
          <h1 className="fw-bold">Our Vision</h1>
          <div className="mt-5 text-1">
            <p className="">
              We envision a world where businesses and governments play a
              central role in creating a low-carbon, resilient, green and
              sustainable future. By equipping our clients with the tools,
              knowledge, and strategies they need to thrive in the context of
              changing climate, we aim to contribute to the global and national
              transition toward a net-zero, environmentally sustainable economy.
            </p>
          </div>
        </div>

        {/* our mission */}
        <div className="container mission mt-5 mb-5">
          <h1 className="fw-bold">Our Mission</h1>
          <div className="mt-5 text-1">
            <p className="">
              Our mission is to lead the way in climate action by helping
              governments, organizations, businesses and communities
            </p>
          </div>
          <div className="mission-card mt-4 d-flex gap-2 flex-wrap justify-content-center">
            {missionList.map((items) => (
              <div
                key={items.id}
                className="card p-2"
                // style={{ width: "18rem" }}
              >
                <image>
                  <i className={items.icon} style={{ fontSize: "30px" }}></i>
                </image>
                <div className="card-body">
                  <h4 className="card-title fw-bold mb-4">{items.title}</h4>
                  <p className="card-text m-0">{items.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <GoToTop />
    </>
  );
};

export default AboutPage;
