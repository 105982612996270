import React from "react";
import StringData from "../String.json";

const NoPage = () => {
  return (
    <>
      <div class="d-flex align-items-center justify-content-center w-100 mb-5">
        <div class="row justify-content-center w-100">
          <div class="col-lg-4">
            <div class="text-center">
              <img
                src={StringData.nopage.errorimg}
                alt=""
                class="img-fluid"
                width="500"
              />
              <h1 class="fw-semibold mb-7 fs-9">Opps!!!</h1>
              <h4 class="fw-semibold mb-7">
                This page you are looking for could not be found.
              </h4>
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoPage;
