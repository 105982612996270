import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../Home/Home";
import Servicepage from "../Pages/ServicePage/ServicePage";
// import PortfolioPage from "../PortfolioPage/PortfolioPage";
import BlogPage from "../Pages/BlogPage/BlogPage";
import NoPage from "../NoPage/NoPage";
import GoToTop from "../GoToTop/GoToTop";
import BlogOne from "../Pages/BlogPage/BlogDetail/BlogOne";

import AboutPage from "../Pages/AboutPage/AboutPage";

const BodyRoute = () => {
  return (
    <>
      <div className="bodysection">
        <Routes>
          <Route path="/" exact element={<Home />}></Route>
          <Route path="/services" exact element={<Servicepage />}></Route>
          {/* <Route path="/portfolio" exact element={<PortfolioPage />}></Route> */}
          <Route path="/blog" exact element={<BlogPage />}></Route>
          <Route path="/blog/1" exact element={<BlogOne />}></Route>
        
          <Route path="/about" exact element={<AboutPage />}></Route>

          <Route path="*" exact element={<NoPage />}></Route>
        </Routes>
      </div>
      <GoToTop />
    </>
  );
};

export default BodyRoute;
