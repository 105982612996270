import React from "react";
import { Link } from "react-router-dom";
import StringData from "../../String.json";
import "./blogpage.css";

const BlogPage = () => {
  return (
    <>
      <div className="container blog-page mt-5" style={{marginBottom:"140px"}}>
        <div className="row me-2 ms-2 blog-page-header border rounded p-4 d-flex flex-column gap-2 justify-content-center align-items-center">
          <h1 className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12 fw-bold">{StringData.blogpage.headerTitle}</h1>
          <p className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">{StringData.blogpage.headerDescription}</p>
        </div>
        <div className="blog-card mt-4 d-flex flex-wrap">
          {StringData.blogpage.blogdata.map((item, index) => (
            <div key={index} className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 card-container p-4">
              <Link to={item.link} className="card">
                <img
                  src={item.image}
                  className="card-img-top"
                  alt={item.title}
                />
                <div className="card-body">
                  <h5 className="card-title fw-bold">{item.title}</h5>
                  <p className="card-text">
                    {item.description}
                  </p>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default BlogPage;
