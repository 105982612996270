import React from "react";
import { Link } from "react-router-dom";
import StringData from "../../../String.json";
import "./blogone.css"

const BlogOne = () => {
  return (
    <div className="container blog-detail mt-5" style={{marginBottom:"200px"}}>
      <div className="go-back text-start">
        <Link to="/blog" className="text-black text-decoration-underline ">
          Go Back
        </Link>
      </div>
      <div className="row blog-detail-content mt-4">
        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-12   blog-detail-header text-start">
          <h1 className="fw-bolder">
            COP29: A Climate Summit of High Stakes and Heated Drama
          </h1>
          <p className="m-0 text-secondary">Muktinath Climate Care limited,</p>
          <p className="m-0 text-secondary">Basundhara, Kathmandu</p>
        </div>
        <div className="blog-detail-img text-start mt-4">
          <img
            src={StringData.blogpage.imageTwo}
            alt="Muktinath Climate Care"
          />
        </div>
        <div className="blog-detail-paragraph text-start mt-4">
          <p className="fs-4">
            Baku, Azerbaijan – COP29 was a whirlwind of hope, frustration, and
            compromise. As global leaders and activists converged on the
            oil-rich nation, the conference was marked by protests, bitter
            debates, and groundbreaking yet controversial agreements.
          </p>
          <p>
            <b className="fs-3">A Bold Pledge, But Is It Enough?</b> <br /> The
            highlight was the commitment by developed nations to deliver $300
            billion annually by 2035 for climate finance; however, this amount
            is 77% short of what is being requested by LDCs, SIDS and other
            climate vulnerable countries. This three-fold increase from the
            unmet $100 billion target of 2020 was heralded as historic by some
            but slammed by others. Developing nations, led by India and Bolivia,
            labeled the figure “an insult,” arguing it falls woefully short of
            addressing the escalating impacts of climate change. The mix of
            grants and loans sparked further discontent.
          </p>
          <p>
            <b className="fs-3">LDCs and SIDS Fight for Justice</b> <br /> Least
            Developed Countries (LDCs), demanded stronger support for loss and
            damage. While partial progress on the Loss and Damage Fund was
            achieved, critical elements were deferred, leaving vulnerable
            nations in a precarious position. Key representatives lamented the
            absence of minimum allocation floors for LDCs and Small Island
            Developing States (SIDS).
          </p>

          <p>
            <b className="fs-3">Oil, Gas, and Controversy</b> <br /> The host
            country, Azerbaijan, faced backlash for its pro-oil rhetoric.
            President Ilham Aliyev’s remarks describing fossil fuels as a “gift
            from God” fueled distrust, overshadowing efforts to transition to
            renewable energy. Activists accused the COP process of favoritism
            toward authoritarian hosts with fossil-fuel economies, raising
            questions about the credibility of future conferences.
          </p>

          <p>
            <b className="fs-3">China’s Quiet Power Move</b> <br /> China
            emerged as a critical player, voluntarily committing to climate
            finance contributions. Though still classified as a “developing
            nation” under UN guidelines, its transparency on financial support
            bolstered its standing as a potential climate leader amid
            geopolitical uncertainty.
          </p>

          <div className="blog-detail-img text-start mt-4">
            <img
              height={400}
              src={StringData.blogpage.imageOne}
              alt="Muktinath Climate Care"
              style={{ width: "100%", objectFit: "cover" }}
            />
          </div>

          <p className="mt-4">
            <b className="fs-3">Nepal Stance at COP29</b> <br /> At COP29 in
            Baku, Nepal has emphasized its vulnerability to climate change and
            pressed for actionable commitments from the global community.
            Represented by Rt.Hon. President Ram Chandra Paudel and a delegation
            of climate experts, Nepal highlighted issues such as the increased
            risk of glacial lake outbursts, landslides, and monsoon-related
            disasters, which are exacerbating economic and social
            vulnerabilities.
          </p>

          <h1 className="fw-bold">Nepal Focuses on:</h1>
          <p>
            <b className="fs-5">Climate Finance and Loss & Damage:</b> Nepal
            advocated for simplified access to the Climate Loss and Damage Fund
            established at COP28. It called for financial mechanisms that
            specifically address the challenges faced by mountainous and
            developing nations like Nepal, which, despite negligible emissions,
            bear severe climate impacts
          </p>

          <p>
            <b className="fs-5">Mountain Agenda:</b> The delegation showcased
            the unique challenges of the Himalayan region, emphasizing the
            disproportionate impact of climate change on glaciers, water
            resources, and ecosystems. Nepal called for increased investment in
            monitoring glacial lakes and reducing risks associated with glacial
            outbursts
          </p>

          <p>
            <b className="fs-5">Adaptation and Resilience:</b> Highlighting the
            impacts on agriculture, water security, and rural livelihoods, Nepal
            sought funding for community-based adaptation initiatives. This
            includes nature-based solutions like reforestation, watershed
            management, and the promotion of climate-resilient agriculture
          </p>

          <p>
            <b className="fs-5">Carbon Markets and Emission Measurement:</b>{" "}
            Nepal advocated for fair mechanisms to trade carbon credits and
            ensure transparent emission measurement systems. This aligns with
            its Nationally Determined Contributions (NDCs) and its aim to
            enhance climate mitigation efforts
          </p>
          <p>
            Also, At the side-lines of COP29 in Baku, Nepal and Sweden Signed a
            bilateral (G2G) cooperation agreement on emissions trading under the
            framework of Article 6, Subarticle 6.2 of the Paris Agreement. This
            agreement allows Nepal to generate and trade Internationally
            Transferred Mitigation Outcomes (ITMOs) with Sweden. Nepal aims to
            leverage its natural resources, especially forests, to generate
            Internationally Transferred Mitigation Outcomes (ITMOs) for trade
            under this mechanism. Such ITMOs are certified reductions in
            greenhouse gas emissions that can be transferred between countries
            to help meet their respective Nationally Determined Contributions
            (NDCs). The deal aligns with Nepal's broader climate finance
            strategy, including its efforts to raise as much as $300 million by
            2028 through forest-based carbon trading under initiatives like the
            LEAF (Lowering Emissions by Accelerating Forest Finance) Coalition
            Partnership. This collaboration will help Nepal monetize its carbon
            storage potential while addressing challenges like forest
            degradation and ensuring sustainable forest management. The revenue
            is intended to support climate adaptation projects, disaster
            preparedness, and community resilience in Nepal.
          </p>

          <p className="mt-4">
            <b className="fs-3">Where Does COP29 Leave Us?</b> <br /> The
            outcomes of COP29, while signaling incremental progress, highlighted
            widening rifts between rich and poor nations. The $300 billion
            pledge was seen by some as a short-term appeasement rather than a
            long-term solution. Meanwhile, unresolved issues, such as fossil
            fuel subsidies and National Adaptation Plans, were deferred to
            future talks. <br /> As the dust settles, the world is left
            grappling with a crucial question: can a fractured global community
            rise above political posturing to address the climate crisis before
            it’s too late? COP29 might have ended, but the battle for a
            sustainable future has just begun. As the climate clock ticks, all
            eyes now turn to COP30, set to be held in Brazil in 2025, with hopes
            for even greater ambition and unity.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlogOne;
